<template>
  <div class="flex space-x-2 text-9pt font-bold leading-5">
    <slot />

    <img
      v-tooltip.right="{
        content: content,
        triggers: ['click', 'hover'],
        placement,
      }"
      :src="blueIcon ? infoIconBlue : infoIcon"
      alt="info-icon"
      class="relative top-1 h-[.6rem] w-[.6rem] cursor-pointer"
      @click.prevent
    />
  </div>
</template>

<script setup lang="ts">
import infoIcon from "~/assets/info.svg";
import infoIconBlue from "~/assets/info.png";

withDefaults(
  defineProps<{
    blueIcon?: boolean;
    content: string;
  }>(),
  { blueIcon: false }
);

const placement = useSiteWindowSize();
</script>
