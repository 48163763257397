export function useContentCount() {
  const { loggedIn: enabled } = storeToRefs(useUserStore());
  const { data, suspense } = useQuery({
    ...queries().bookshelf.contentCount,
    enabled,
  });

  useSafeQuerySuspense({ enabled, suspense });

  return { contentCount: data };
}
