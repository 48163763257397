<template>
  <svg viewBox="0 0 203 165" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M74 68C92.7777 68 108 52.7777 108 34C108 15.2223 92.7777 0 74 0C55.2223 0 40 15.2223 40 34C40 52.7777 55.2223 68 74 68ZM74 54C85.0457 54 94 45.0457 94 34C94 22.9543 85.0457 14 74 14C62.9543 14 54 22.9543 54 34C54 45.0457 62.9543 54 74 54Z"
      fill="#1F2329"
    />
    <path
      d="M0 125.021C0 102.935 17.9028 85 40 85H80.7372C78.9485 89.4833 77.5489 94.1638 76.58 99H40C25.6464 99 14 110.656 14 125.021V151H83.4197C85.8941 155.979 88.8657 160.667 92.2696 165H0V125.021Z"
      fill="#1F2329"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M163.979 67.253L164.837 80.1107C171.997 81.8339 178.312 85.7272 183.046 91.056L182.773 90.5727L194.454 84.9544C195.963 84.2286 197.777 84.7971 198.601 86.2544L202.457 93.0703C203.282 94.5276 202.835 96.3747 201.436 97.2943L190.604 104.414L190.398 104.049C191.439 107.39 192 110.943 192 114.626C192 118.76 191.293 122.729 189.995 126.418L190.737 125.183L201.379 132.583C202.754 133.539 203.152 135.397 202.29 136.832L198.256 143.545C197.394 144.98 195.566 145.501 194.077 144.736L182.547 138.814L182.661 138.624C177.972 143.733 171.805 147.464 164.839 149.141L163.979 162.031C163.868 163.702 162.48 165 160.806 165H152.975C151.3 165 149.913 163.702 149.801 162.031L148.954 149.323C142.088 147.836 135.962 144.364 131.207 139.536L119.98 145.303C118.49 146.068 116.663 145.547 115.801 144.112L111.767 137.399C110.905 135.964 111.303 134.106 112.678 133.15L122.88 126.055C121.661 122.469 121 118.625 121 114.626C121 110.825 121.597 107.164 122.703 103.731L112.388 96.6907C111.005 95.7467 110.591 93.8922 111.44 92.4495L115.415 85.702C116.265 84.2592 118.088 83.7225 119.584 84.4745L130.816 90.12C135.624 85.082 141.899 81.4566 148.956 79.9295L149.801 67.253C149.913 65.5823 151.3 64.2841 152.975 64.2841H160.806C162.48 64.2841 163.868 65.5823 163.979 67.253ZM156.5 136.126C168.374 136.126 178 126.5 178 114.626C178 102.752 168.374 93.1263 156.5 93.1263C144.626 93.1263 135 102.752 135 114.626C135 126.5 144.626 136.126 156.5 136.126Z"
      fill="#1F2329"
    />
  </svg>
</template>
