<template>
  <div class="flex flex-wrap items-center justify-start gap-4 md:gap-12">
    <div
      class="flex items-center gap-6 rounded-lg bg-lexmea-gray-100 px-5 py-3 shadow-md"
    >
      <div class="relative">
        <UserProfileIcon
          class="h-12 w-12"
          :profileImage="user.profile_image"
          :username="user.name"
        />
        <a
          v-tooltip.bottom="
            'Ändere dein Profilbild / deinen Nutzernamen in den Profileinstellungen.'
          "
          href="/einstellungen/profil"
          class="absolute -bottom-2 -right-2 flex h-6 w-6 cursor-pointer rounded-full bg-lexmea-gray-100 p-1 transition hover:bg-lexmea-gray-400"
        >
          <SettingIcon />
        </a>
      </div>
      <p class="text-base">
        Hallo
        <br />
        {{ user?.name }}!
      </p>
    </div>
    <div class="flex flex-auto justify-center gap-4 sm:gap-12 md:justify-start">
      <div
        v-for="(slice, i) in contentSlices"
        :key="i"
        class="grid grid-cols-[auto,1fr] text-6pt text-gray-500"
      >
        <template v-for="content in slice" :key="content.name">
          <span class="justify-self-end">
            {{ content.count }}
          </span>
          <span class="ml-2 truncate whitespace-nowrap">
            {{ content.name }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { IUser } from "~/models/IUser";

interface Props {
  user: IUser;
}

defineProps<Props>();

const { contentCount } = useContentCount();

const contentSlices = computed(() =>
  (contentCount.value ?? []).reduce(
    (acc, cur, i) => {
      const bucket = Math.floor(i / 3);
      if (!acc[bucket]) {
        acc[bucket] = [];
      }
      acc[bucket].push(cur);
      return acc;
    },
    [] as { name: string; count: number }[][]
  )
);
</script>
